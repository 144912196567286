@import '../../assets/styles/variables.scss';

.files-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    // overflow-x: auto;
    gap: 10px;
}

.file-icon {
    margin-right: 10px;
    color: $green2-color;
    cursor: pointer;
    height: 24px;
    width: 24px;
}
.file-preview {
    // margin-right: 10px;
    cursor: pointer;
    max-height: 60px;
    border-radius: 8px;
}