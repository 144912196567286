//Применение миксинов
// .my-button {
//   @include button($primary-color);
//   @include center;
// }

//Font regular
@mixin font-regular-10px {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
}
@mixin font-regular-12px {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}
@mixin font-regular-13px {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}
@mixin font-regular-14px {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}
@mixin font-regular-15px {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
}

//Font medium
@mixin font-medium-10px {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
}
@mixin font-medium-12px {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}
@mixin font-medium-13px {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}
@mixin font-medium-14px {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}
@mixin font-medium-15px {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}

//Font semi-bold
@mixin font-semibold-12px {
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
}
@mixin font-semibold-13px {
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
}
@mixin font-semibold-14px {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
@mixin font-semibold-15px {
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
}
@mixin font-semibold-18px {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}
@mixin font-semibold-20px {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
@mixin font-semibold-28px {
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
}