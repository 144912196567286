.scroll-btn {
    display: none;
    position: fixed;
    bottom: 30px;
    right: 20px;
    height: 40px;
    width: 40px;
    background-color: rgba(51, 153, 153, .5);
    border-radius: 5px;
    cursor: pointer;
    @media (min-width: 425px) {
        right: 30px;
        height: 50px;
        width: 50px;
    }
    @media (min-width: 768px) {
        bottom: 40px;
        right: 40px;
        height: 60px;
        width: 60px;
    }
    @media (min-width: 1200px) {
        bottom: 50px;
        right: 50px;
    }

    &--active {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__arrow {
        color: white;
        font-size: 25px;
        @media (min-width: 425px) {
            font-size: 30px;
        }
    }
}