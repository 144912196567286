@import '../../../assets/styles/variables.scss';

.menu-side {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    min-width: 320px;
    background-color: #fff;
    height: 0;
    opacity: 0;
    overflow: scroll;
    transition: height 0.5s, opacity 0.6s;
    &__wrapper {
        padding: 20px 24px 40px;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        color: $grey7-color;
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (min-width: 992px) {
            display: none;
        }
    }
    &__header__logo {
        // font-family: 'Audiowide';
        // font-size: 28px;
        // line-height: 36px;
        // color: $orange3-color;
        // position: relative;
        // display: flex;
        // align-items: center;
        // background: linear-gradient(135deg, #FCCF31 0%, #F55555 100%);
        // background-clip: text;
        // color: transparent;
        // cursor: pointer;
        // &::before {
        //     content: '';
        //     position: absolute;
        //     // top: 0;
        //     left: -10px;
        //     height: 8px;
        //     width: 8px;
        //     background-color: $orange4-color;
        //     border-radius: 50%;
        // }
    }
    &__header__logo img {
        height: 40px;
    }
    &__header__btn-close {
        font-size: 30px;
        color: $grey5-color;
        cursor: pointer;
    }
    &__content {
        margin-top: 30px;
        @media (min-width: 992px) {
            margin-top: 0;
        }
    }
    &__content>button {
        display: none;
        margin: 30px auto 0 auto;
    }
    &__links__item {
        position: relative;
        height: 56px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $grey1-color;
    }
    &__links__item__icon {
        height: 40px;
        width: 40px;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__links__item__icon__img {
        height: 25px;
        width: 25px; 
        color: '#97ADB6'
    }
    &__links__item__icon--active {
        background: linear-gradient(135deg, #FFE985 0%, #FA742B 100%);
        box-shadow: 0px 8px 16px rgba(218, 221, 223, 0.36);
        border-radius: 8px;
        color: #fff
    }
    &__links__item__text {
        line-height: 40px;
    }
    &__footer {
        margin-top: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    &__footer:hover>.menu-side__footer__icon {
        background: linear-gradient(135deg, #FFE985 0%, #FA742B 100%);
        box-shadow: 0px 8px 16px rgba(218, 221, 223, 0.36);
        border-radius: 8px;
        color: #fff;
    }
    &__footer__icon {
        height: 40px;
        width: 40px;
        margin-right: 8px;
        display: flex;
        align-items: center;
    }
    &__footer__text {
        line-height: 40px;
    }

    &--active {
        height: 100vH;
        opacity: 1;
    }
}

.menu-side .unread-badge {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}

.menu-side__links__item {
    font-size: 14px;
    color: #787486;
    &:hover, &.active {
        color: $orange4-color;
        font-weight: 700;
    }
}

@media (min-width: 992px) {
    .menu-side {
        position: static;
        height: 100%;
        opacity: 1;
        overflow: visible;
        transition: none;
        z-index: auto;
        &__wrapper {
            padding-top: 24px;
        }
        &__header__btn-close {
            display: none;
        }
        &__content>button {
            display: block;
        }
        &__links__item {
            cursor: pointer;
        }
        &__links__item:hover>.menu-side__links__item__icon {
            background: linear-gradient(135deg, #FFE985 0%, #FA742B 100%);
            box-shadow: 0px 8px 16px rgba(218, 221, 223, 0.36);
            border-radius: 8px;
            color: #fff;
        }
        &__footer {
            margin-top: auto;
        }
    }
}