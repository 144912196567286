.advantages {
    padding: 48px 0;
    @media (min-width: 1400px) {
        padding: 120px 0;
    }
    p {
        margin-top: 12px;
        @media (min-width: 576px) {
            margin-top: 14px;
            font-size: 14px;
            line-height: 140%;
        }
        @media (min-width: 768px) {
            margin-top: 16px;
            font-size: 16px;
            line-height: 140%;
        }
        @media (min-width: 992px) {
            margin-top: 18px;
            font-size: 18px;
            line-height: 140%;
        }
        @media (min-width: 1400px) {
            margin-top: 24px;
            font-size: 24px;
            line-height: 140%;
        }
    }
    &__intro {
        margin-top: 12px;
    }
    &__wrapper {
        @media (min-width: 576px) {
            margin-top: 30px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 30px;
        }
        @media (min-width: 768px) {
            margin-top: 36px;
        }
        @media (min-width: 992px) {
            margin-top: 48px;
            column-gap: 150px;
        }
        @media (min-width: 1400px) {
            margin-top: 64px;
            column-gap: 300px;
        }
    }
    &__item {
        margin-top: 24px;
        @media (min-width: 576px) {
            margin-top: 0
        }
    }

    &__instruments {
        
        @media (min-width: 576px) {
            display: grid;
            margin-top: 30px;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 20px;
        }
        @media (min-width: 768px) {
            column-gap: 50px;
            row-gap: 30px;
        }
        @media (min-width: 1400px) {
            column-gap: 0;
            row-gap: 0;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, auto);
        }
    }
    &__promo {
        grid-column-start: 1;
        grid-column-end: 3;
        position: relative;
        margin: 0 -20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 320px;
        width: 320px;
        background-color: rgba(238, 235, 231, .3);
        border-radius: 50%;
        @media (min-width: 360px) {
            margin: 0 auto;
        }
        @media (min-width: 768px) {
            height: 380px;
            width: 380px;
        }
        @media (min-width: 992px) {
            height: 440px;
            width: 440px;
        }
        @media (min-width: 1400px) {
            height: 500px;
            width: 500px;
            // grid-column-start: 2;
            // grid-column-end: 3;
            grid-area: 1 / 2 / 3 / 3; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
        }
    }
    &__promo__circle1 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 270px;
        width: 270px;
        background-color: #EEEBE7;
        border-radius: 50%;
        @media (min-width: 768px) {
            height: 320px;
            width: 320px;
        }
        @media (min-width: 992px) {
            height: 355px;
            width: 355px;
        }
        @media (min-width: 1400px) {
            height: 415px;
            width: 415px;
        }
    }
    &__promo__circle2 {
        position: relative;
        height: 250px;
        width: 250px;
        background-color: #fff;
        border-radius: 50%;
        overflow: hidden;
        @media (min-width: 768px) {
            height: 300px;
            width: 300px;
        }
        @media (min-width: 992px) {
            height: 325px;
            width: 325px;
        }
        @media (min-width: 1400px) {
            height: 385px;
            width: 385px;
        }
    }
    &__promo__title {
        padding: 50px 25px;
        font-size: 18px;
        line-height: 140%;
        color: #E39A0F;
        text-align: center;
        @media (min-width: 768px) {
            padding-top: 65px;
            font-size: 20px;
            line-height: 140%;
        }
        @media (min-width: 992px) {
            padding-top: 80px;
            font-size: 24px;
            line-height: 140%;
        }
        @media (min-width: 1400px) {
            // padding-top: 80px;
            font-size: 28px;
            line-height: 140%;
        }
    }
    &__promo__img {
        position: absolute;
        height: 135px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        @media (min-width: 768px) {
            height: 160px;
        }
        @media (min-width: 992px) {
            height: 185px;
        }
        @media (min-width: 1400px) {
            height: 210px;
        }
    }
    &__promo__builder {
        height: 200px;
        position: absolute;
        bottom: 0;
        right: 0;
        @media (min-width: 576px) {
            height: 240px;
            right: -100px;
        }
        @media (min-width: 768px) {
            height: 280px;
            right: -100px;
        }
        @media (min-width: 992px) {
            height: 320px;
            // top: -95%;
            // left: 30%;
        }
        @media (min-width: 1400px) {
            height: 360px;
            top: -65%;
            left: 35%;
        }
    }
    &__instruments__item {
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 21px;
        @media (min-width: 1400px) {
            margin-top: 0;
        }

        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(6) {
            @media (min-width: 1400px) {
                flex-direction: row-reverse;
            }
        }
        &:nth-child(4) {
            @media (min-width: 1400px) {
                position: relative;
                top: -60px;
            }
        }
        &:nth-child(5) {
            @media (min-width: 1400px) {
                position: relative;
                top: -60px;
            }
        }
        &:nth-child(6) {
            @media (min-width: 1400px) {
                position: relative;
                top: -140px;
                right: -120px;
            }
        }
        &:nth-child(7) {
            @media (min-width: 1400px) {
                flex-direction: column;
                row-gap: 20px;
                width: 350px;
                margin: 20px auto 0;
                text-align: center;
            }
        }
        &:nth-child(8) {
            @media (min-width: 1400px) {
                @media (min-width: 1400px) {
                    position: relative;
                    top: -140px;
                    left: -120px;
                }
            }
        }
    }
   
    &__instruments__item__icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 10px;
        height: 53px;
        width: 53px;
        background-color: #85776C;
        border-radius: 50%;
        @media (min-width: 576px) {
            height: 60px;
            width: 60px;
        }
        @media (min-width: 768px) {
            margin: 0;
            height: 70px;
            width: 70px; 
        }
        @media (min-width: 992px) {
            margin: 10px;
            height: 80px;
            width: 80px; 
        }
        @media (min-width: 1400px) {
            height: 100px;
            width: 100px;   
        }
    }
    &__instruments__item__icon-wrapper::after {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 1px;
        right: -15px;
        top: 0;
        background-color: #85776C;
        @media (min-width: 768px) {
            right: -10px;
        }
        @media (min-width: 992px) {
            right: -15px;
        }
        @media (min-width: 1400px) {
            
        }
        
    }
    &__instruments__item:nth-child(2) &__instruments__item__icon-wrapper::after,
    &__instruments__item:nth-child(4) &__instruments__item__icon-wrapper::after,
    &__instruments__item:nth-child(6) &__instruments__item__icon-wrapper::after {
        @media (min-width: 1400px) {
            right: auto;
            left: -15px;
        }
    }
    &__instruments__item:nth-child(7) &__instruments__item__icon-wrapper::after {
        @media (min-width: 1400px) {
            bottom: -15px;
            top: auto;
            width: 100%;
            height: 1px;
        }
    }
    &__instruments__item__icon-wrapper img {
        height: 50%;
    }

    &__instruments__item__descr {
        flex: 1;
        font-size: 14px;
        line-height: 140%;

        @media (min-width: 768px) {
            font-size: 16px;
            line-height: 140%;
        }
        @media (min-width: 992px) {
            font-size: 18px;
            line-height: 140%;
        }
        @media (min-width: 1400px) {
            font-size: 24px;
            line-height: 140%;
        }
    }
    &__instruments__item__descr span {
        color: #E39A0F;
        font-weight: 600;
    }
}