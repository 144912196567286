.reviews {
    padding: 48px 0;
    background-color: #EEEBE7;

    @media (min-width: 1400px) {
        padding: 120px 0;
    }
    &__wrapper {
        @media (min-width: 992px) {
            margin-top: 45px;
            display: grid;
            grid-template-columns: 1fr 2fr;
        }
        @media (min-width: 1400px) {
            margin-top: 45px;
            display: grid;
            // grid-template-columns: 1fr 2fr;
            grid-template-columns: 30% 70%;
        }
    }

    &__img {
        margin-top: 16px;
        height: 200px;
        width: 100%;
        object-fit: cover;
        @media (min-width: 576px) {
            height: 250px;
            object-fit: cover;
        }
        @media (min-width: 992px) {
            height: 100%;
            object-fit: cover;
        }
    }

    &__slider {
        position: relative;
        @media (min-width: 768px) {
            margin-top: 40px;
        }
    }
    &__slider__wrapper {
        margin-top: 30px;
        height: 500px;
        overflow: hidden;
        @media (min-width: 380px) {
            height: 440px;
        }
        @media (min-width: 576px) {
            height: 400px;
        }
        @media (min-width: 768px) {
            height: auto;
        }
    }
    &__slider__inner {
        transition: all 0.6s;
        @media (min-width: 768px) {
            width: 250%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-auto-flow: column;
        }
    }

    &__item {
        height: 250px;
        padding: 20px;
        @media (min-width: 380px) {
            height: 220px;
        }
        @media (min-width: 576px) {
            height: 200px;
        }
        @media (min-width: 768px) {
            height: auto;
        }
    }

    &__user {
        display: flex;
        align-items: center;
        column-gap: 12px;
        
    }
    &__user__photo {
        height: 52px;
        width: 52px;
        border-radius: 50%;
    }

    &__name {
        font-size: 16px;
        line-height: 140%;
        @media (min-width: 992px) {
            font-size: 18px;
            line-height: 140%;
        }
    }

    &__position {
        margin-top: 3px;
        font-size: 14px;
        line-height: 140%;
    }

    &__date {
        margin-top: 5px;
        display: flex;
        column-gap: 4px;
        color: #05B0BA;
        font-size: 14px;
        line-height: 140%;
    }
    &__date__icon {
        height: 16px;
        width: 16px;;
    }
    &__date__text {
        font-size: 14px;
        line-height: 140%;
        flex: 1;
    }

    &__text {
        margin-top: 12px;
    }

    &__control {
        width: 150px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        rotate: 90deg;
        top: 70px;
        right: -70px;
        @media (min-width: 768px) {
            rotate: initial;
            top: -30px;
            right: 0;
        }
        @media (min-width: 992px) {
            // rotate: initial;
            top: -50px;
            // right: 0;
        }
        @media (min-width: 1400px) {
            // rotate: initial;
            top: -100px;
            // right: 0;
        }
    }
    &__control svg {
        cursor: pointer;
    }
    &__control .disabled g {
        opacity: 0.3;
    }
    
}