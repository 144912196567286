.business {
    padding: 20px 0 50px 0;

    @media (min-width: 1400px) {
        padding: 120px 0 110px 0;
    }
    &__wrapper {
        position: relative;
        margin-top: 24px;
        @media (min-width: 768px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, auto);
            column-gap: 20px;
        }
        @media (min-width: 1400px) {
            margin-top: 48px;
            grid-template-columns: 30% 30% 40%;
            column-gap: 50px;
        }
    }
    &__results {
        margin-top: 24px;
        padding: 12px;
        background-color: #E39A0F;
        @media (min-width: 768px) {
            position: absolute;
            left: 0;
            width: 60%;
        }
        @media (min-width: 1400px) {
            right: 0;
            margin: 0 auto;
            width: 550px;
        }
    }
    &__results p:nth-child(2) {
        margin-top: 8px;
    }
    &__functionality {
        margin-top: 16px;
        @media (min-width: 768px) {
            margin-top: 170px;
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 3;
        }
        @media (min-width: 1400px) {
            margin-top: 0;
        }
    }
    &__functionality p {
        margin-top: 12px;
    }
    &__steps {
        margin-top: 12px;
    }
    &__steps__item {
        padding: 4px 12px;
        display: flex;
        // justify-content: space-between;
        align-items: flex-start;
        column-gap: 34px;
    }
    &__steps__item__number {
        position: relative;
        min-width: 30px;
        font-size: 40px;
        font-weight: 700;
        line-height: 100%;
        color: #339999;
    }
    &__steps__item__number::after {
        content: '';
        position: absolute;
        right: -10px;
        top: 0;
        display: block;
        height: 100%;
        width: 1px;
        background-color: #339999;

    }
    &__steps__item__number.color--main::after {
        background-color: #E39A0F;

    }
    &__img {
        margin-top: 24px;
        height: 120px;
        width: 100%;
        object-fit: cover;
        @media (min-width: 576px) {
            height: 200px;
        }
        @media (min-width: 768px) {
            grid-column-start: 2;
            grid-column-end: 3;
            height: auto;
        }
        @media (min-width: 1400px) {
            width: 95%;
            grid-column-start: 3;
            grid-column-end: 4;
        }
    }
    &__risks {
        margin-top: 24px;
        @media (min-width: 1400px) {
            margin-top: 200px;
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 2;
        }
    }
    &__btn {
        margin: 24px auto 0 auto;
        background-color: #E39A0F;
        border-radius: 0;
        padding: 15px 30px;
        @media (min-width: 768px) {
            position: absolute;
            bottom: 1vW;
            left: 100px;
        }
        @media (min-width: 992px) {
            bottom: 6vW;
        }
        @media (min-width: 1400px) {
            bottom: 0;
            right: 100px;
            left: auto;
        }
    }
}