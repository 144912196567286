.price {
    padding: 48px 0;
    background: url('../../../../assets//images/price/price-bg.png') left center / cover no-repeat;
    @media (min-width: 576px) {
        padding: 62px 0 75px;
    }
    @media (min-width: 768px) {
        padding: 76px 0 100px;
    }
    @media (min-width: 992px) {
        padding: 90px 0 125px;
    }
    @media (min-width: 1400px) {
        padding: 90px 0 150px;
    }
    &__wrapper {
        position: relative;
        margin-top: 27px;
        @media (min-width: 992px) {
            // margin-top: 62px;
            // padding: 0 150px;
            display: grid;
            column-gap: 30px;
            // grid-template-columns: repeat(2, 1fr);
            grid-template-columns: 40% 50%;
            grid-template-rows: repeat(2, auto);
        }
        @media (min-width: 1400px) {
            margin-top: 62px;
            padding: 0 100px;
            // display: grid;
            // grid-template-columns: repeat(2, 1fr);
            // grid-template-rows: repeat(2, auto);
        }
    }
    &__item {
        margin-top: 24px;
        padding: 11px;
        display: flex;
        column-gap: 28px;
    }
    &__item:nth-child(1) {
        @media (min-width: 992px) {
            grid-area: 1 / 1 / 3 / 2; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
            height: fit-content;
        }
    }
    &__item:nth-child(2), &__item:nth-child(3) {
        width: 80%;
        // @media (min-width: 992px) {
        //     width: 90%;
        // }
    }
    &__item__number {
        position: relative;
        width: 35px;
        font-size: 64px;
        font-weight: 700;
        line-height: 100%;
        color: #339999;
    }
    &__item__number::after {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: #339999;
        right: -15px;
        top: 0;
    }
    &__item__text {
        flex: 1;
    }
    &__item__text span {
        color: #339999;
        font-weight: 600;
    }
    &__builder {
        position: absolute;
        right: -20px;
        bottom: -48px;
        height: 200px;
        @media (min-width: 576px) {
            height: 240px;
            bottom: -75px;
        }
        @media (min-width: 768px) {
            height: 280px;
            bottom: -100px;
        }
        @media (min-width: 992px) {
            height: 320px;
            bottom: -125px;
        }
        @media (min-width: 1400px) {
            height: 360px;
            bottom: -150px;
        }
    }
}