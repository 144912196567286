@import '../../../../assets/styles/variables.scss';

.chats-list {
    display: none;
    width: 100%;
    border-right: 1px solid $grey3-color;
    // height: calc(100vH - 52px);
    &--visible {
        display: block;
    }

    &>.main__content {
        &::-webkit-scrollbar {
            display: none;
        }
    }

    @media (min-width: 992px) {
        display: block;
    }

}