.solutions {
    position: relative;
    padding: 80px 0 50px 0;
    background: url('../../../../assets/images/solutions/solutions-bg.png') center center /cover no-repeat;
    @media (min-width: 992px) {
        padding: 120px 0 80px 0;
    }
    @media (min-width: 1400px) {
        padding: 170px 0 130px 0;
    }
    & p {
        font-size: 12px;
        margin-top: 8px;
        @media (min-width: 576px) {
            font-size: 16px;
            line-height: 140%; 
            margin-top: 14px;
        }
        @media (min-width: 768px) {
            font-size: 18px;
            line-height: 140%;
            margin-top: 16px;
        }
        @media (min-width: 992px) {
            font-size: 20px;
            line-height: 140%;
            margin-top: 18px;
        }
        @media (min-width: 1400px) {
            font-size: 24px;
            line-height: 140%;
            margin-top: 24px;
        }
    }
    & p:first-child {
        margin-top: 12px;
        @media (min-width: 576px) {
            margin-top: 14px;
        }
        @media (min-width: 768px) {
            margin-top: 16px;
        }
        @media (min-width: 992px) {
            margin-top: 18px;
        }
        @media (min-width: 1400px) {
            margin-top: 24px;
        }
    }
    &__wrapper {
        @media (min-width: 768px) {
            display: flex;
            justify-content: space-between;
            column-gap: 150px;
        }
        @media (min-width: 992px) {
            column-gap: 200px;
        }
        @media (min-width: 1400px) {
            column-gap: 300px;
        }
    }
    &__item {
        width: 70%;
    }
    &__item:nth-child(2) {
        margin-top: 24px;
        @media (min-width: 768px) {
            margin-top: 0;
        }
    }
    &__builder {
        position: absolute;
        bottom: 15px;
        right: 0;
        height: 200px;
        @media (min-width: 576px) {
            right: 30px;
            height: 240px;
        }
        @media (min-width: 768px) {
            height: 280px;
            right: 50%;
            transform: translateX(50%);
        }
        @media (min-width: 992px) {
            height: 320px;
        }
        @media (min-width: 1400px) {
            height: 360px;
        }
    }
}