// .input {

//     &--internal {
//         margin-left: 10px;
//         width: 60%;
//         outline: none;
//     }
// }

.input label {
    display: flex;
    column-gap: 10px;
}

.input--internal {
    flex: 1;
    &:focus-visible {
        outline: none;
    }
    @media (max-width: 768px) {
        &:focus {
            font-size: 16px;
        }
    }
}

