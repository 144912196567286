.spinner {
    height: 100vH;
    width: 100vW;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup .spinner {
    height: 100%;
    width: 100%;
}