@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/variables.scss';

.popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: scroll;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 3;
    padding: 30px 10px;
    
    &--active {
        display: block;
    }
    &__dialog {
        position: relative;
        padding: 20px;
        margin: 0 auto;
        width: 100%;
        // height: 500px;
        max-width: 500px;
        background-color: #fff;
        border-radius: 16px;
    }
    &__close {
        position: absolute;
        top: 10px;
        right: 20px;
        cursor: pointer;
        font-size: 30px;
    }
    &__title {
        text-align: center;
        @include font-semibold-20px;
    }
}

.new-order {
    &__qualifications {
        margin-top: 30px;
    }
    &__qualifications__title {
        @include font-medium-15px;
    }
    &__qualifications__item.input  {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        height: auto;
    }
    &__qualifications__item--active.input  {
        background-color: $grey3-color;
        .new-order__qualifications__item__name {
            padding: 4px 8px;
            background-color: $orange3-color;
            color: white;
            border-radius: 10px;
        }
    }
    &__qualifications__item__price  {
        padding: 4px 8px;
        background-color: $grey3-color;
        color: $orange6-color;
        @include font-semibold-14px;
        border-radius: 10px;
    }
    &__btn {
        margin: 40px auto;
        height: 48px;
        width: 100%;
    }
}

@media (max-width: 375px) {
    .react-datepicker {
        margin-left: -14px;
        &__time-container {
            width: auto;
        }
        &__time-container .react-datepicker__time .react-datepicker__time-box {
            width: auto;
        }
        &__month {
            margin: 0;
        }
    }
}