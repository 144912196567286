@import '../../../assets/styles/variables.scss';

.menu-main {
    position: relative;
    height: 0;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $grey7-color;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: height 0.5s;
    z-index: 3;

    font-family: Gilroy;
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
    @media (min-width: 576px) {
        font-size: 14px;
        line-height: 140%;
    }
    @media (min-width: 768px) {
        font-size: 16px;
        line-height: 140%;
    }
    @media (min-width: 992px) {
        font-size: 18px;
        line-height: 140%;
    }
    @media (min-width: 1400px) {
        font-size: 20px;
        line-height: 140%;
    }

    &__btn-close {
        font-size: 45px;
        color: red;
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
    }
    &__links {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    &__links-item {
        margin-top: 60px;
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        &:hover {
            background: url('../../../assets/images/header/link-bg.svg') center center / cover no-repeat;
        }
        @media (min-width: 992px) {
            margin-top: 0;
            margin-right: 30px;
            color: $black-color;
            // font-size: 16px;
            font-size: inherit;
        }
    }
    &__btn {
        position: absolute;
        bottom: 50px;
        right: 0;
        left: 0;
        margin: 0 auto;
        // height: 28px;
        width: 180px;
    }
    &--active {
        height: 100%;
        position: fixed;
    }
}

.menu-main .btn {
    padding: 15px 30px;
    width: fit-content;
    display: block;
    border: none;
    background-color: #E39A0F;
    color: white;
    text-align: center;
    font-size: inherit;
    font-weight: 500;
    
    cursor: pointer;
    transition: background 0.2s;
}
@media (min-width: 992px) {
    .menu-main {
        position: static;
        height: auto;
        width: auto;
        overflow: visible;
        background-color: transparent;
        justify-content: space-between;
        transition: none;
        &__btn-close {
            display: none;
        }
        &__links {
            flex-direction: row;
            text-align: left;
        }
        &__btn {
            position: static;
            bottom: 50px;
            right: 0;
            left: 0;
            margin: 0;
        }
    }
}
