.client-problems {
    background-color: #EEEBE7;
    padding: 20px 0;
    &__wrapper {
        @media (min-width: 576px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(4, auto);
            column-gap: 30px;
        }
        @media (min-width: 768px) {
            grid-template-columns: repeat(7, 1fr);
            grid-template-rows: repeat(3, auto);
            grid-template-areas: 
            "a a a . b b b"
            "c c d d d e e"
            "f f f . g g g";
            column-gap: 0;
            
        }
        @media (min-width: 992px) {
            // padding: 0 50px;
        }
    }
    &__item {
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 21px;
    }
    &__item:nth-child(2), &__item:nth-child(5), &__item:nth-child(7) {
        @media (min-width: 576px) {
            flex-direction: row-reverse;
            text-align: end;
        }
    }
    &__item:nth-child(1) {
        @media (min-width: 768px) {
            grid-area: a;
            margin-left: 80px;
            
        }
    }
    &__item:nth-child(2) {
        @media (min-width: 768px) {
            grid-area: b;
            margin-right: 80px;
        }
    }
    &__item:nth-child(3) {
        @media (min-width: 768px) {
            grid-area: c;
        }
    }
    &__item:nth-child(5) {
        @media (min-width: 768px) {
            grid-area: e;
        }
    }
    &__item:nth-child(6) {
        @media (min-width: 768px) {
            grid-area: f;
            margin-left: 80px;
        }
    }
    &__item:nth-child(7) {
        @media (min-width: 768px) {
            grid-area: g;
            margin-right: 80px;
        }
    }
    &__item__icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 10px;
        height: 53px;
        width: 53px;
        background-color: #339999;
        border-radius: 50%;
        @media (min-width: 576px) {
            height: 60px;
            width: 60px;
        }
        @media (min-width: 768px) {
            margin: 0;
            height: 70px;
            width: 70px; 
        }
        @media (min-width: 992px) {
            margin: 10px;
            height: 80px;
            width: 80px; 
        }
        @media (min-width: 1400px) {
            height: 100px;
            width: 100px;   
        }
    }
    &__item__icon-wrapper::after {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 1px;
        right: -15px;
        top: 0;
        background-color: #339999;
        @media (min-width: 768px) {
            right: -10px;
        }
        @media (min-width: 992px) {
            right: -15px;
        }
    }
    &__item:nth-child(2) &__item__icon-wrapper::after, 
    &__item:nth-child(5) &__item__icon-wrapper::after, 
    &__item:nth-child(7) &__item__icon-wrapper::after {
        @media (min-width: 576px) {
            left: -15px;
            // width: 2px;
        }
        @media (min-width: 768px) {
            // width: 2px;
            left: -10px;
        }
        @media (min-width: 992px) {
            // width: 1px;
            left: -15px;
        }
    }

    &__item__icon-wrapper img {
        height: 50%;
    }
    
    &__item__descr {
        flex: 1;
        font-size: 14px;
        line-height: 140%;
        // @media (min-width: 576px) {
        //     font-size: 16px;
        //     line-height: 140%; 
        // }
        @media (min-width: 768px) {
            font-size: 16px;
            line-height: 140%;
        }
        @media (min-width: 992px) {
            font-size: 18px;
            line-height: 140%;
        }
        @media (min-width: 1400px) {
            font-size: 24px;
            line-height: 140%;
        }
    }
    &__item__descr span {
        color: #E39A0F;
        font-weight: 600;
    }
    &__answer {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        height: 290px;
        width: 290px;
        border-radius: 50%;
        background-color: rgba(187, 180, 174, .3);
        @media (min-width: 576px) {
            grid-row-start: 3;
            grid-row-end: 4;
            grid-column-start: 1;
            grid-column-end: 3;
        }
        @media (min-width: 768px) {
            grid-area: d;
        }
        @media (min-width: 992px) {
            height: 340px;
            width: 340px;
        }
        @media (min-width: 1400px) {
            height: 390px;
            width: 390px;
        }
    }
    &__answer__circle1 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 240px;
        width: 240px;
        border-radius: 50%;
        background-color: #EEEBE7;
        @media (min-width: 992px) {
            height: 290px;
            width: 290px;
        }
        @media (min-width: 1400px) {
            height: 340px;
            width: 340px;
        }
    }
    &__answer__circle2 {
        height: 224px;
        width: 224px;
        border-radius: 50%;
        background-color: #fff;
        @media (min-width: 992px) {
            height: 274px;
            width: 274px;
        }
        @media (min-width: 1400px) {
            height: 324px;
            width: 324px;
        }
    }
    &__answer__title {
        padding: 50px 25px;
        font-size: 18px;
        line-height: 140%;
        color: #E39A0F;
        opacity: 1;
        @media (min-width: 992px) {
            font-size: 20px;
        }
        @media (min-width: 1400px) {
            font-size: 24px;
        }
    }
    &__answer__img {
        position: absolute;
        height: 150px;
        left: 0;
        right: 0;
        bottom: 15px;
        margin: 0 auto;
        @media (min-width: 992px) {
            height: 200px;
        }
        @media (min-width: 1400px) {
            height: 250px;
        }
    }
}
