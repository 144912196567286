.drop-down-menu {
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 100%;
    right: 0;
    width: 160px;
    // padding: 15px 15px 8px;
    border-radius: 4px;
    box-shadow: 0px 3px 6px 0px grey;
    background-color: white;
    z-index: 3;
    transition: all 0.6s;
    &__link {
        padding: 14px 20px;
        border-bottom: 1px solid #d9d9d9;
    }
    &__link, &__link a {
        font-size: 14px;
        color: #787486;
    }
    &__link:hover {
        font-weight: 700;
    }
    // &__link a {
    //     font-size: 14px;
    //     color: #787486;
    // }
    // &__link a:hover {
    //     font-weight: 700;
    // }
}

// div:hover > .header__img + .drop-down-menu, .drop-down-menu:hover {
//     height: fit-content;
//     opacity: 1;
// }

// .drop-down-menu.active {
//     height: fit-content;
//     opacity: 1;
// }

.header__user-info.active .drop-down-menu {
    height: fit-content;
    opacity: 1;
}

@media (min-width: 992px) {
    .drop-down-menu:hover  {
        height: fit-content;
        opacity: 1;
    }
}