.page404 {
    display: flex;
    flex-direction: column;
    height: 100vH;

    font-family: Gilroy;
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
    @media (min-width: 576px) {
        font-size: 14px;
        line-height: 140%;
    }
    @media (min-width: 768px) {
        font-size: 16px;
        line-height: 140%;
    }
    @media (min-width: 992px) {
        font-size: 18px;
        line-height: 140%;
    }
    @media (min-width: 1400px) {
        font-size: 20px;
        line-height: 140%;
    }
    .main__content {
        padding-bottom: 0;
    }
    & .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 140%;
        @media (min-width: 576px) {
            font-size: 24px;
            line-height: 140%;
        }
        @media (min-width: 768px) {
            font-size: 30px;
            line-height: 140%;
        }
        @media (min-width: 992px) {
            font-size: 36px;
            line-height: 140%;
        }
        @media (min-width: 1400px) {
            font-size: 48px;
            line-height: 140%;
        }
    }
    
    &__wrapper {
        position: relative;
        width: fit-content;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    &__wrapper img {
        max-height: 50vH;
        max-width: 100%;
    }
    &__title {
        color: #E39A0F;
        text-shadow: 0px 3.754px 10.01px rgba(0, 0, 0, 0.03), 0px 2.502px 3.754px rgba(0, 0, 0, 0.07), 0px 3.128px 5.63px rgba(0, 0, 0, 0.08);
        font-size: 20px;
        line-height: 120%;
        @media (min-width: 576px) {
            font-size: 30px;
            line-height: 120%;
        }
        @media (min-width: 768px) {
            font-size: 36px;
            line-height: 120%;
        }
        @media (min-width: 992px) {
           font-size: 48px; 
           line-height: 120%;
        }
        @media (min-width: 1400px) {
            font-size: 60px;
            line-height: 120%;
        }
    }
    &__wrapper button {
        padding: 10px 30px;
        width: fit-content;
        display: block;
        border: none;
        background-color: #E39A0F;
        color: white;
        text-align: center;
        font-size: inherit;
        font-weight: 500;
        
        cursor: pointer;
        transition: background 0.2s;
        // @media(min-width: 992px) {
        //     position: absolute;
        //     right: 0;
        // }
    }
}