@import '../../assets/styles/variables.scss';

.file-uploader-new {
    
    &>label {
        display: block;
        cursor: pointer;
    }
    input[type="file"] {
        display: none;
    }
    &__files-list {
        margin-top: 10px;
    }
    &__files-list__item {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
    }
    &__btn-delete {
        font-size: 20px;
        color: $red2-color;
        cursor: pointer;
    }
}