@import "../../assets/styles/mixins.scss";

// .register {
//     &-page {
//         height: calc(100vH - 50px);
//         background-color: rgb(224, 224, 224);
//         padding-top: 20px;
//     }
//     &-form {
//         width: 420px;
//         margin: 0 auto;
//         padding: 30px;
//         background-color: rgb(255, 255, 255);
//         border-radius: 16px;
//         box-shadow: 0px 4px 26px rgb(0 0 0 / 50%);
//         // padding-top: 20px;
//         &-title {
//             @include font-semibold-20px;
//             text-align: center;
//         }
//     }
// }

// .step-indicator {
//     margin-top: 20px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     &-line {
//         width: 44%;
//         height: 1px;
//         background-color: rgb(222, 222, 222);
//     }
//     &-circle {
//         height: 24px;
//         width: 24px;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         border-radius: 100%;     
//         &.active {
//             background-color: rgb(104, 150, 90);
//             color: white;
//         }
//     }
// }

// .roles-wrapper {
//     padding: 10px;
//     display: flex;
//     justify-content: space-around;
// }
// .role {
//     &-img {
//         width: 60px;
//     }
    // &-item {
    //     width: 120px;
    //     padding: 10px;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     border-radius: 10px;
    //     cursor: pointer;
    //     &.active {
    //         background-color: rgb(191, 191, 191);
    //         border: solid 1px rgb(53, 60, 63);
    //     }
    // }
// }
  
// .group-buttons {
//     margin-top: 10px;
//     display: flex;
//     justify-content: space-between;
// }

// .group-sekretKey {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
// }

// .form-notification {
//     @include font-regular-10px;
//     margin-top: 10px;
// }


.register-page {
    padding: 10px 0;
    height: 100vH;
    background: linear-gradient(90deg, hsla(197, 14%, 57%, 1) 0%, hsla(192, 17%, 94%, 1) 100%);
    &__logo {
        text-align: center;
    }
    &__logo img {
        height: 50px;
    }
    &__title {
        text-align: center;
        font-family: Dancing Script;
        font-weight: 700;
        font-size: 36px;
    }
    &__subtitle {
        text-align: center;
        font-size: 14px;
    }
    &__roles {
        margin-top: 20px;
    }
    &__roles__wrapper {
        padding: 10px;
        display: flex;
        justify-content: space-around;
    }
    &__roles__item {
        width: 120px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        cursor: pointer;
    }
    &__roles__item--active {
        background-color: rgb(191, 191, 191);
        border: solid 1px rgb(53, 60, 63);
    }
    &__roles__item__img {
        width: 60px;
    }
    &__roles__item__text {
        margin-top: 10px;
        font-size: 14px;
    }
}

.step-indicator {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    &__line {
        width: 44%;
        height: 1px;
        background-color: rgb(222, 222, 222);
    }
    &__circle {
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;     
    }
    &__circle--active {
        background-color: rgb(104, 150, 90);
        color: white;
    }
}

.sekret-key {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
        margin-right: 20px;
    }
}

.phone-confirmation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    height: 40px;
    width: 100%;
    padding: 10px;
    font-size: 13px;
    border-radius: 16px;
    
    border: 1px solid transparent;
    // background: #fff;
    
    // font-size: 14px;
    &__links {
        display: flex;
        column-gap: 10px;  
    }
    &__code-block {
        margin-top: 20px;
        &>div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            & .input {
                margin-top: 0;
                width: 140px;
            }
        }
        &>p {
            @include font-regular-12px;
            margin-top: 10px;
        }
    }
}

[confirmation-type="call"] {
    background: rgb(104, 150, 90);
    color: white;
    filter: opacity(90%);
    &:hover {
        filter: opacity(100%);
    }
}

[confirmation-type="telegram"] {
    background: #229ED9;
    color: white;
    filter: opacity(90%);
    &:hover {
        filter: opacity(100%);
    }
}

#inputPhone {
    background: none rgb(252, 252, 252);
    &:focus {
        outline: none;
        font-size: 16px;
    }
    & ~ .check-mark {
        font-size: 20px;
        color: rgb(104, 150, 90);
    }
}

.input-phone-wrapper:focus-within {
    border: 2px solid black;
}