//Colors
//Применение перемынных:
// .my-element {
//     background-color: $primary-color;
//     color: $secondary-color;
  
//     font-size: $font-size-medium;
//     border-radius: $border-radius;
// }

//White-colors
$white-color: #FFFFFF;
//Grey-color
$grey1-color: #F5F6F7;
$grey2-color: #E9EBEC;
$grey3-color: #D5D7DA;
$grey4-color: #BFC3C7;
$grey5-color: #97ADB6;
$grey6-color: #9298A0;
$grey7-color: #787486;
$grey8-color: #4A474C;


//Black-color
$black-color: #09101D;

// Orange-color
$orange1-color: #FFE985;
$orange2-color: #FCD2A2;
$orange3-color: #FF9E2D;
$orange4-color: #FC9C4A;
$orange5-color: #E48432;
$orange6-color: #FA742B;
$orange7-color: #E39A0F;
$orange1-gradient: linear-gradient(135deg, $orange1-color 0%, $orange5-color 100%);
$orange2-gradient: linear-gradient(135deg, $orange2-color 0%, $orange3-color 100%);

//Red-color
$red1-color: #FEB692;
$red2-color: #EA5455;
$red-gradient: linear-gradient(135deg, $red1-color 0%, $red2-color 100%);

//Green-color
$green1-color: #88F1BF;
$green2-color: #00B388;
$green3-color: #00B288;
$green-gradient: linear-gradient(135.96deg, $green1-color 0%, $green2-color 101.74%);

//Blue-color
$blue1-color: #ABDCFF;
$blue2-color: #0396FF;
$blue-gradient: linear-gradient(135deg, $blue1-color 0%, $blue2-color 100%);

//Purple-color
$purple1-color: #CE9FFC;
$purple2-color: #7367F0;
$purple-gradient: linear-gradient(135deg, $purple1-color 0%, $purple2-color 100%);

//Yellow-color
$yellow1-color: #ffec4b;
$yellow2-color: #fae000;
