@import '../../assets/styles/variables.scss';

.button-burger {
    height: fit-content;
    padding: 5px;
    text-align: center;
    cursor: pointer;
    span {
        display: block;
        height: 1.5px;
        width: 25px;
        background-color: $grey5-color;
        margin-bottom: 6px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    @media (min-width: 992px) {
        display: none;
    }
}