@import '../../../../../../../../assets/styles/variables.scss';
@import '../../../../../../../../assets/styles/mixins.scss';

.message {
    width: 60%;
    min-width: 227px;
    padding: 10px 12px;
    border-radius: 16px 16px 16px 0;
    
    &__text {
        @include font-regular-15px;
        word-break: break-all;
    }
    &__text > p:not(:first-child) {
        margin-top: 3px;
    }
    &__date-time {
        padding-top: 5px;
        @include font-regular-10px;
        color: $grey5-color;
        text-align: end;
    }
    &--own {
        background-color: $orange4-color;
        border-radius: 16px 16px 0 16px;
        margin: 0 0 0 auto;
    }
    &--own > &__text, &--own > &__date-time {
        color: #fff;
    }
    &--interlocutor {
        background-color: #fff;
        border-radius: 16px 16px 16px 0;
        // margin: 0 auto 0 0;
    }
}