.home-header {
    padding: 15px 0;
    background-color: rgba(251, 251, 251, 0.9);
    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__logo {
        height: 40px;
        margin: -10px 0;
        @media (min-width: 992px) {
            height: 45px;
        }
        @media (min-width: 1400px) {
            height: 50px;
        }
    }
}