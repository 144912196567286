@import '../../../../../../assets/styles/variables.scss';

.input-panel {
    // height: 74px;
    width: 100%;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    
    padding: 12px 16px 26px;
    background-color: #fff;
    &__inputs {
        display: flex;
        align-items: flex-end;
        column-gap: 10px;
    }
    &__files {
        margin-bottom: 10px;
        width: 100%;
        padding: 0 10px;
        background-color: #fff;
        border-bottom: solid 1px $grey1-color;
    }
    &__files__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        column-gap: 20px;
        margin-bottom: 10px;
    }
    &__files__item img {
        max-height: 50px;
        border-radius: 8px;
        @media (min-width: 768px) {
            max-height: 60px;
        }
    }
    &__files__item__btn-delete {
        font-size: 20px;
        color: $red2-color;
        cursor: pointer;
        margin: 0 0 0 auto;
        width: fit-content;
        padding-left: 10px;
        @media (min-width: 768px) {
            font-size: 24px;
        }
    }
    &__icon {
        color: $grey5-color;
        height: 27px;
        width: 29px;
        cursor: pointer;
    }
    &__input {
        height: 41px;
        width: 100%;
        padding: 10px;
        background-color: $grey1-color;
        border-radius: 8px;
        line-height: 21px;
        resize: none;
        &:focus {
            outline: none !important;
            border:2px solid $orange2-color;
            // box-shadow: 0 0 10px #719ECE;
        }
        &::-webkit-scrollbar {
            display: none;
        }
        @media (max-width: 768px) {
            &:focus {
                font-size: 16px;
            }
        }
    }
}