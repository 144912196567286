@import '../../assets/styles/variables.scss';

.events-calendar {
    padding: 16px 11px;
    border-radius: 16px;
    background-color: $white-color;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        width: 0;
    }
    &__item {
        // min-width: 50px;
        padding: 6px;
        border-radius: 8px;
        background-color: $grey1-color;
    }
    &__item--marked {
        cursor: pointer;
    }
    &__item>p {
        margin: 6px auto 0 auto;
        text-align: center;
    }
    &__item__eventsMark {
        height: 4px;
        width: 4px;
        background-color: $orange4-color;
        border-radius: 50%;
    }
    &__info {
        background-color: #fff;
        margin-top: -10px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
    }
    &__info__close {
        text-align: center;
        font-size: 25px;
        color: red;
        cursor: pointer;
    }
}