.manual-page {
    // height: 10000px;
    font-family: Gilroy;
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
    @media (min-width: 576px) {
        font-size: 13px;
        line-height: 150%;
    }
    @media (min-width: 768px) {
        font-size: 14px;
        line-height: 150%;
    }
    @media (min-width: 992px) {
        font-size: 16px;
        line-height: 150%;
    }
    @media (min-width: 1400px) {
        font-size: 18px;
        line-height: 150%;
    }
    &__menu-trigger {
        margin: -6px 0 10px 0;
        @media (min-width: 992px) {
            display: none;
        }
    }

    & .title {
        font-family: Gilroy;

        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
        @media (min-width: 576px) {
            font-size: 18px;
            line-height: 140%;
        }
        @media (min-width: 768px) {
            font-size: 20px;
            line-height: 140%;
        }
        @media (min-width: 992px) {
            font-size: 24px;
            line-height: 140%;
        }
        @media (min-width: 1400px) {
            font-size: 30px;
            line-height: 140%;
        }
    }
    & .subtitle {
        font-family: Gilroy;

        font-size: 14px;
        font-weight: 600;
        line-height: 140%;
        @media (min-width: 576px) {
            font-size: 16px;
            line-height: 140%;
        }
        @media (min-width: 768px) {
            font-size: 18px;
            line-height: 140%;
        }
        @media (min-width: 992px) {
            font-size: 20px;
            line-height: 140%;
        }
        @media (min-width: 1400px) {
            font-size: 24px;
            line-height: 140%;
        }
    }
}

.images-wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-around;
    padding-bottom: 40px;
    border-bottom: 2px dashed #ddd;
    @media (min-width: 768px) {
        flex-direction: row;
        max-height: 80vH;
    }
}
.images-wrapper img {
    object-fit: contain;
    max-height: 400px;
    @media (min-width: 768px) {
        max-width: 40%;
    }
}