@import '../../assets/styles/variables.scss';

.unread-badge {
    // position: absolute;
    // right: 5px;
    // top: 50%;
    // transform: translateY(-50%);
    display: none;
    padding: 4px;
    background-color: $orange3-color;
    color: #fff;
    border-radius: 50%;

    &--with-counter {
        padding: 2px 7px;
    }

    &--visible {
        display: block;
    }
}