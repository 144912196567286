.recovery-page {
    padding: 10px 0;
    height: 100vH;
    background: linear-gradient(90deg, hsla(197, 14%, 57%, 1) 0%, hsla(192, 17%, 94%, 1) 100%);
    &__logo {
        text-align: center;
        cursor: pointer;
    }
    &__logo img {
        height: 50px;
    }
    &__title {
        text-align: center;
        font-family: Dancing Script;
        font-weight: 700;
        font-size: 36px;
    }
    &__subtitle {
        text-align: center;
        font-size: 14px;
    }
    &__options {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        column-gap: 50px;
    }
    &__options__item {
        display: flex;
        column-gap: 10px;
        font-size: 14px;
    }
}