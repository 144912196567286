@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';


.input--withIntLabel {
    & label {
        display: flex;
        column-gap: 10px;
    }
    & input {
        flex: 1;
        width: 100%;
        &:focus-visible {
            outline: none;
        }
    }
}


.input--withExtLabel {
    & label {
        display: block;
        @include font-regular-12px;
    }
    & input {
        margin-top: 5px;
    }
}


.input--withCheckbox {
    cursor: pointer;
    position: relative;
    & input[type="checkbox"] {
        display: none;
    }
    & span {
        display: block;
        &::before {
            content: '';
            position: absolute;
            right: 15px;
            height: 16px;
            width: 16px;
            border-radius: 4px;
            background-color: $grey5-color;
        }
        &::after {
            content: '';
            scale: 0;
            position: absolute;
            top: 13px;
            right: 16px;
            height: 10px;
            width: 12px;
            background: url('../../assets/icons/components/checkbox-arrow.svg') no-repeat;
            background-size: cover;
            background-position: center;
            transition: all 0.2s;
        }
    }
    & input:checked {
        & + span::before {
            background-color: $orange4-color;
        }
        & + span::after {
            scale: 1;
        }
    }
}

.input--with-suggestions {
    position: relative;
    .suggestions {
        position: absolute;
        // bottom: 0;
        right: 0;
        background-color: #fff;
        z-index: 3;
    }
    .suggestions__item {
        padding: 5px 10px;
        cursor: pointer;

    }
    .suggestions__item:hover {
        background-color:rgb(224, 224, 224);
    }
}
