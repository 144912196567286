.messages-list {
    // position: relative;
    padding: 24px 0 30px;
    height: 100%;
    width: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

.date-indicator {
    width: fit-content;
    margin: 20px auto;
    padding: 5px 15px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid grey;
}
.date-indicator:first-child {
    margin-top: 0;
}