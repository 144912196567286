.vacant-orders {
    &__filter {
        margin: 0 10px;
        @media(min-width: 376px) {
            margin: 0 30px;
        }
        @media(min-width: 576px) {
            margin: 0 80px;
        }
        @media(min-width: 992px) {
            margin: 0 120px;
        }
    }
    &__filter.absolute {
        position: absolute;
        top: 24px;
        left: 0;
        right: 0;
        z-index: 2;
    }
}

.map {
    margin-top: -24px;
    position: relative;
    z-index: 0;
}
.map .leaflet-popup-content {
    margin: 0;
}

// .my-marker {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 24px;
//     height: 24px;
//     border-radius: 50%;
//     color: #fff;
//     background-color: #333;
// }
