@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.filter {
    padding: 4px;
    display: flex;
    justify-content: space-between;
    background-color: $grey2-color;
    border-radius: 20px;
    &__item {
        width: 100%;
        padding: 7px;
        text-align: center;
        @include font-medium-13px;
        color: $grey6-color;
        cursor: pointer;
    }
    &__item--active {
        background: $orange1-gradient;
        border-radius: 20px;
        color: $white-color;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    }
    &--absolute {
        position: absolute;
        top: 24px;
        left: 0;
        right: 0;
        z-index: 2;
    }
}