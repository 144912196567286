@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';


.about-menu {
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        padding: 15px 25px;
        background: $orange3-color;
        border-radius: 20px;
        text-transform: uppercase;
        color: #FFFFFF;
        box-shadow: 3px 4px darken($orange3-color, 20%), 0px 10px 10px rgb(0 0 0 / 25%);
        cursor: pointer;
        transition: 0.5s all;

        &:first-child {
            margin-top: 0;
        }

        &:hover {
            transform: translateY(5px);
            box-shadow: 0px 0px transparent, 0px 5px 10px rgba(0,0,0,0.25);
        }
    }
    &__back-arrow {
        display: flex;
        column-gap: 5px;
        align-items: center;
        margin-top: -14px;
        color: $orange4-color;
        cursor: pointer;
    }
}

.contract, .user-agreement {
    margin-top: 20px;
}