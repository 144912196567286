.promo {
    
    &__wrapper {
        position: relative;
        height: 431px;
        background: url('../../../../assets/images/promo/promo_bg.png') 135% 20px/auto 65vw no-repeat;
        margin-right: -20px;
        padding-top: 20px;
        @media (min-width: 360px) {
            padding: 30px 0;
            background-position-y: -10px;
            // background-size: 60vW;
            // background-position-y: -20px;
        }
        @media (min-width: 576px) {
            padding: 40px 0;
            background: url('../../../../assets/images/promo/promo_bg.png') right -60px top -20px/auto 100% no-repeat;
            // padding-top: 50px;
            // background-position-x: 100%;

        }
        @media (min-width: 768px) {
            padding: 50px 0;
            background: url('../../../../assets/images/promo/promo_bg.png') right -20px/auto 110% no-repeat;
            // background-size: auto 100%;
        }
        @media (min-width: 992px) {
            height: 530px;
            padding: 60px 0;
            background: url('../../../../assets/images/promo/promo_bg.png') right -20px/auto 110% no-repeat;
        }
        @media (min-width: 1200px) {
            
        }
        @media (min-width: 1400px) {
            // height: 775px;
            height: auto;
            padding: 90px 0;
            background: url('../../../../assets/images/promo/promo_bg.png') right -20px/auto 110% no-repeat;
        }
    }
    &__title {
        width: 225px;
        max-width: 980px;
        font-size: 18px;
        text-transform: uppercase;
        line-height: 150%;
        @media (min-width: 330px) {
            width: 250px;
        }
        @media (min-width: 576px) {
            width: 60%;
        }
        @media (min-width: 768px) {
            font-size: 24px;
        }
        @media (min-width: 992px) {
            font-size: 30px;
        }
        @media (min-width: 1400px) {
            font-size: 40px;
        }
    }
    &__title > span {
        color: #E39A0F;
        font-size: 24px;
        font-weight: 500;
        @media (min-width: 768px) {
            font-size: 30px;
            font-weight: 600;
        }
        @media (min-width: 992px) {
            font-size: 40px;
        }
        @media (min-width: 1400px) {
            font-size: 64px;
            
        }
    }
    &__descr {
        width: 50%;
        margin-top: 12px;
        line-height: 140%;
        @media (min-width: 360px) {
            width: 45%;
        }
    }
    &__btns-wrapper {
        @media (min-width: 768px) {
            display: flex;
            column-gap: 30px;
        }        
    }
    &__btns-wrapper button {
        width: 150px;
        padding: 12px 15px;
    }
    &__btns-wrapper button:nth-child(2) {
        margin-top: 16px;
        @media (min-width: 768px) {
            margin-top: 0;
        }
    }
    
    &__builder {
        // display: none;
        position: absolute;
        height: 200px;
        right: 90px;
        bottom: 10px;
        z-index: 2;
        @media (min-width: 376px) {
            // height: 220px;
            // right: 110px;
            right: 30vW;

        }
        @media (min-width: 576px) {
            height: 240px;
            bottom: 0px;
        }
        @media (min-width: 768px) {
            height: 280px;
            bottom: 0px;
        }
        @media (min-width: 992px) {
            height: 320px;
            
        }
        @media(min-width: 1400px) {
            height: 360px;
            // bottom: 0px;
            right: 450px;
        }
    }
    &__iphone {
        // display: none;
        position: absolute;
        height: 260px;
        right: 0px;
        bottom: 10px;
        z-index: 1;
        @media (min-width: 376px) {
            height: 286px;
            // right: 10px;
            right: 5vW;
        }
        @media (min-width: 576px) {
            height: 330px;
        }
        @media (min-width: 992px) {
            height: 360px;
            right: 10vW;
            bottom: 30px;
        }
        @media(min-width: 1400px) {
            height: 380px;
            right: 130px;
            
        }
    }
    
}