.arrow {
    cursor: pointer;

    &-icon {
        display: flex;
        align-items: center;
        height: 30px;
        width: 20px;
    }
}

.back-arrow--with-text {
    display: flex;
    column-gap: 5px;
    align-items: center;
    cursor: pointer;
}