@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.header {
    height: 52px;
    padding: 8px 0;
    background-color: $grey1-color;
    border-bottom: 1px solid $grey3-color;
    // box-shadow: rgb(170 170 170) 2px 2px 2px 2px;
    @media (min-width: 992px) {
        height: 66px;
    }
    @media (min-width: 1400px) {
        height: 71px;
    }
    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__logo {
        display: none;
        padding-left: 24px;

        @media (min-width: 992px) {
            display: block;
            min-width: 320px;
        }
    }
    &__logo img {
        height: 40px;
        @media (min-width: 992px) {
            height: 45px;
        }
        @media (min-width: 1400px) {
            height: 50px;
        }
    }

    &__content__wrapper {
        flex: 1;
    }
    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 10px;
    }
    &__title {
        width: 100%;
        margin-left: 10px;
        @include font-semibold-18px;
        @media (min-width: 992px) {
            font-size: 20px;
        }
        @media (min-width: 1400px) {
            font-size: 24px;
        }
    }
    // &__title + div {
    //     position: relative;
    //     padding-right: 20px;
    //     cursor: pointer;
    //     &::after {
    //         content: '';
    //         display: block;
    //         position: absolute;
    //         top: 50%;
    //         transform: translateY(-50%);
            
    //         right: 0;
    //         border: 6px solid transparent;
    //         border-top: 6px solid grey;
    //     }
    //     &:hover {
    //         &::after {
    //             transform: rotate(180deg) translateY(100%);
    //         }
    //     }  
    // }
    &__user-info {
        position: relative;
        padding-right: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            border: 6px solid transparent;
            border-top: 6px solid grey;
            @media (min-width: 992px) {
                transform: translateY(-25%);
            }
        }
        &.active {
            &::after {
                transform: rotate(180deg) translateY(100%);
            }
        }  
    }
    &__user-name {
        display: none;
        @media(min-width: 992px) {
            display: block;
        }
    }
    &__img {
        height: 36px;
        width: 40px;
        border-radius: 50%;
        object-fit: cover;
    }
    &__img--grey {
        color: $grey5-color;
    }
    &__img--orange {
        color: $orange4-color;
    }
    &-group {
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
        height: 36px;
        @media (min-width: 375px) {
            column-gap: 20px;
        }
    }
    &__btn {
        cursor: pointer;
    }
    &__btn--transparent {
        background: none transparent;
        color: $grey6-color;
        @media (max-width: 375px) {
            font-size: 12px;
        }
    }
    
}