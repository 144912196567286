@import '../../../../../../assets/styles/variables.scss';
@import '../../../../../../assets/styles/mixins.scss';

.chat-card {
    position: relative;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: #fff;
    border-radius: 16px;
    cursor: pointer;

    &__photo__img {
        height: 48px;
        width: 48px;
        border-radius: 50%;
        object-fit: cover;
    }
    &__text {
        width: 100%;
    }
    &__user-name {
        @include font-medium-15px;
    }
    &__order-number {
        @include font-medium-14px;
    }
    &__details {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        @include font-regular-14px;
        color: $grey7-color
    }
    &__time-indicator {
        position: relative;
        &::before {
            content: '';
            display: block;
            position: absolute;
            left: -10px;
            top: 50%;
            // bottom: 0;
            height: 4px;
            width: 4px;
            background-color: $grey7-color;
            border-radius: 50%;
        }
    }
    &__counter {
        position: absolute;
        top: 0;
        right: 10px;
        padding: 1px 16px;
        background-color: $orange4-color;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        @include font-semibold-13px;
        color: #fff
    }

    &--active {
        // border: 1px solid $orange4-color;
        box-shadow: 0px 1px 2px 0px rgba(252,210,162,0.7),
            1px 2px 4px 0px rgba(252,210,162,0.7),
            2px 4px 8px 0px rgba(252,210,162,0.7),
            2px 4px 16px 0px rgba(252,210,162,0.7);
    }
}