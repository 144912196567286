@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';


.card {
    margin-top: 16px;
    padding: 0 16px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(218, 221, 223, 0.36);
    border-radius: 16px;
    &--event {
        margin-top: 0;
        box-shadow: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    &__header {
        position: relative;
        padding-top: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__header--event {
        padding-top: 10px;
    }
    &__order-number {
        @include font-medium-14px;
        color: $black-color;
        cursor: pointer;
    }
    &__order-time {
        @include font-medium-12px;
        color: $grey5-color;
    }
    &__indicator {
        position: absolute;
        top: 0;
        right: 16px;
        height: 4px;
        width: 72px;
        // background-color: $blue2-color;
        &--orange {
            background-color: $orange3-color;
        }
        &--blue {
            background-color: $blue2-color;
        }
        &--green {
            background-color: $green3-color;
        }
        &--purple {
            background-color: $purple2-color;
        }
        &--red {
            background-color: $red2-color;
        }
        &--grey {
            background-color: $grey5-color;
        }
        &--yellow {
            background-color: $yellow2-color;
        }
    }
    &__content {
        position: relative;
        padding: 16px 26px 24px;
        border-bottom: 1px solid $grey3-color;
    }
    &__content--event {
        padding: 16px 26px;
        border-bottom: none;
    }
    &__content__item {
        margin-top: 16px;
        position: relative;
    }
    &__content__item__param {
        @include font-semibold-14px;
        color: $black-color;
    }
    &__content__item__value {
        margin-top: 4px;
        @include font-regular-12px;
        color: $grey7-color;
    }
    &__footer {
        padding: 16px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__footer--event {
        padding: 0 0 10px;
        justify-content: end;
    }
    &__info {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        padding: 5px 8px;
        border-radius: 6px;
        @include font-medium-10px;
        &--orange {
            background-color: rgba(255, 158, 45, 0.12);
            color: $orange3-color;
        }
        &--blue {
            background-color: rgba(3, 150, 255, 0.12);
            color: $blue2-color;
        }
        &--green {
            background-color: rgba(0, 178, 136, 0.12);
            color: $green3-color;
        }
        &--purple {
            background-color: rgba(115, 103, 240, 0.12);
            color: $purple2-color;
            
        }
        &--red {
            background-color: rgba(234, 84, 85, 0.12);
            color: $red2-color;
        }
        &--grey {
            background-color: rgba(151, 173, 182, 0.12);
            color: $orange5-color;
        }
        &--yellow {
            background-color: $yellow1-color;
            color: $black-color;
        }
        &--disabled {
            display: none;
        }
        @media (min-width: 375px) {
            @include font-medium-12px;
        }
    }
    &__info__btn {
        padding: 3px 5px;
        border-radius: 4px;
        background-color: $blue2-color;
        color: $white-color;
        cursor: pointer;
        &--orange {
            background-color: $orange3-color;
        }
        &--blue {
            background-color: $blue2-color;
        }
        &--green {
            background-color: $green3-color;
        }
        &--purple {
            background-color: $purple2-color;
        }
        &--red {
            background-color: $red2-color;
        }
        &--grey {
            background-color: $orange5-color;
        }
        &--disabled {
            display: none;
        }
    }
    &__links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
    }
    &__links__item {
        cursor: pointer;
    }
    &__links__icon {
        height: 20px;
        width: 20px;
        color: $grey5-color;
    }
    
}
.decor {
    position: absolute;
    top: 0;
    left: -26px;
    &__circle {
        height: 14px;
        width: 14px;
        border-radius: 50%;
        border: solid 3px $grey4-color;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__circle-inner {
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background-color: $grey4-color;
    }
    &__dots {
        margin-top: 4px;
        height: calc(100% + 26px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
    }
    &__dots__item {
        height: 2px;
        width: 2px;
        border-radius: 50%;
        background-color: $grey4-color;
    }
    &__divider {
        margin: 16px;
        height: 1px;
        background-color: $grey3-color;
    }
}

.card--specialization .card__header {
    padding-top: 0;
}

.rating-badge {
    position: absolute;
    top: 0;
    right: 6px;
    display: flex;
    align-items: center;
    height: 44px;
    width: 72px;
    padding: 25px 7px 5px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background: rgba(151, 173, 182, .1);
    display: flex;
    justify-content: space-between;
    @include font-medium-12px;

    &__rating-num {
        color: $orange4-color;
    }
    &__orders-num {
        color: $grey5-color;
    }
    @media (min-width: 375px) {
        right: 16px;
    }
    @media (min-width: 992px) {
        height: 50px;
        width: 90px;
        font-size: 15px;
    }
}

.rating-trigger {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 2px 10px;
    background: $orange2-gradient;
    color: #fff;
    cursor: pointer;
}

.empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
}

@media (min-width: 375px) {
    .card {
        &__content__item__param {
            @include font-semibold-15px;
        }
        &__content__item__value {
            @include font-regular-14px;
        }
        &__links {
            gap: 26px;
        }
        // &__links__icon {
        //     height: 22px;
        //     width: 22px;
        // }
    }
}
@media (min-width: 768px) {
    .card {
        &__links__icon {
            height: 22px;
            width: 22px;
        }
    }
}
