.accordion {
    &.active .accordion__arrow {
       transform: rotate(180deg); 
    }
    
    &__content {
        height: 0;
        overflow: hidden;
        transition: all .6s;
    }
    &__content .menu-side__links__item__text {
        padding-left: 30px;
    }

    & .menu-side__links__item {
        justify-content: space-between;
    }
    
    &__arrow svg {
        height: 20px;
        width: 20px;
    }
    
}