.order-page {
    &__footer {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex-flow: column-reverse;
        @media (min-width: 576px) {
            flex-direction: row;
            justify-content: space-between;
        }
    }
}