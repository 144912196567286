@import '../../../../assets/styles/variables.scss';

.search-options {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $grey1-color;
    z-index: 2;
    &__content__wrapper {
        display: grid;
        @media (min-width: 992px) {
            grid-template-columns: 1fr 1fr;
            column-gap: 30px;
        }
    }

    &__footer__wrapper {
        @media (min-width: 576px) {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-around;
            column-gap: 30px;
        }
    }
    
    &__footer .btn {
        height: 40px;
        width: 100%;
        margin-top: 30px;
        @media (min-width: 576px) {
            max-width: 300px;
        }
    }
}

.date-picker {
    display: flex;
    column-gap: 10px;
    
    &__icon {
        margin-right: 5px;
        height: 20px;
        width: 20px;
        color: $grey5-color;
        display: none;
        cursor: pointer;
        @media (min-width: 375px) {
            display: block;
        }
    }
    &__icon--active {
        color: $orange4-color;
    }
}
.react-datepicker-wrapper {
    flex: 1;
    & input {
        width: 100%;
    }
    & input:focus-visible {
        outline: none;
    }
}

// //Скроем реальный чекбокс
// .real-checkbox {
//     display: none;
// }
// //Зададим стили для кастомного чекбокса
// .custom-checkbox {
//     height: 100%;
//     width: 100%;
//     position: relative;
//     cursor: pointer;
//     //Создаем псевдоэлемент с рамкой кастомного чекбокса
//     & span::before {
//         content: '';
//         position: absolute;
//         right: 5px;
//         height: 16px;
//         width: 16px;
//         border-radius: 4px;
//         background-color: $grey5-color;
//     }
//     //Создадим псевдоэлемент который отвечает за отображение, скрытие стрелки
//     & span::after {
//         content: '';
//         scale: 0;
//         position: absolute;
//         top: 3px;
//         right: 6px;
//         height: 10px;
//         width: 12px;
//         background: url('../../../../assets/icons/components/checkbox-arrow.svg') no-repeat;
//         background-size: cover;
//         background-position: center;
//         transition: all 0.2s;
//     }
// }
// //Зададим стили для кастомного чекбокса при нажатом реальном чекбоксе
// .real-checkbox:checked + span::before {
//     background-color: $orange4-color;
// }
// .real-checkbox:checked + span::after {
//     scale: 1;
// }
