.footer {
    padding: 14px 0 11px;
    background-color: #4A474C;
    color: #EEEBE7;
    
    &__wrapper {
        @media (min-width: 480px) {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }
        @media (min-width: 992px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row-reverse;
        }

    }
    &__menu {
        display: flex;
        justify-content: space-around;
        max-width: 495px;
        @media (min-width: 480px) {
            justify-content: end;
            column-gap: 30px;
        }
        @media (min-width: 768px) {
            column-gap: 45px;
        }
        @media (min-width: 992px) {
            column-gap: 60px;
        }
        
    }
    &__menu__item {
        color: #EEEBE7;
    }
    &__trademark {
        margin: 13px auto 0 auto;
        text-align: center;
        width: fit-content;
        @media (min-width: 480px) {
            margin: 0;
        }
    }

}