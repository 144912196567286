.installation {

    &__text, &__text p {
        margin-top: 20px;
    }

    &__steps {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        @media (min-width: 576px) {
            flex-direction: row;
            // gap: 30px;
        }
        @media (min-width: 768px) {
            gap: 30px;
        }
    }
    &__steps__item__descr {
        text-align: center;
    }
    &__steps__item__img {
        display: block;
        margin: 10px auto 0 auto;
        width: 70%;
        @media (min-width: 576px) {
            width: 100%;
        }
    }
}