.image-wrapper {
    position: relative;
    padding-top: 30px;
    margin: 0 auto;
    width: fit-content;

    & img {
        // width: 100%;
        // object-fit: contain;
    }
    & .close-trigger {
        position: absolute;
        top: 0;
        right: -48px;
        color: red;
        font-size: 48px;
        cursor: pointer;
    }
}