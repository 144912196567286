@import '../../../../../../assets/styles/variables.scss';
@import '../../../../../../assets/styles/mixins.scss';

.chat-header {
    // &__content {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     column-gap: 10px;
    // }
    &__text {
        width: 100%;
    }
    &__user-name {
        @include font-medium-15px;
    }
    &__details {
        margin-top: 3px;
        display: flex;
        justify-content: space-between;
        @include font-regular-12px;
        color: $grey7-color
    }
}