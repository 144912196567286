@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.orders-counter {
    margin-top: 20px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    &__item {
        padding: 8px;
        text-align: center;
        color: $white-color;
        box-shadow: 0px 4px 25px rgba(141, 141, 141, 0.1);
        border-radius: 16px;
        min-width: 92px;
        max-width: 92px;
        cursor: pointer;
        &:nth-child(1) {
            background: $orange2-gradient;
            position: relative;
            &.active::before {
                // box-shadow: 0 0 0 2px #fff, 0 0 0 4px $orange4-color;
                content: '';
                position: absolute;
                display: block;
                left: 0;
                bottom: -15px;
                height: 3px;
                width: 100%;
                background: $orange2-gradient;
            }
        }
        &:nth-child(2) {
            background: $blue-gradient;
            position: relative;
            &.active::before {
                // box-shadow: 0 0 0 2px #fff, 0 0 0 4px $orange4-color;
                content: '';
                position: absolute;
                display: block;
                left: 0;
                bottom: -15px;
                height: 3px;
                width: 100%;
                background: $blue-gradient;
            }
        }
        &:nth-child(3) {
            background: $green-gradient;
            position: relative;
            &.active::before {
                // box-shadow: 0 0 0 2px #fff, 0 0 0 4px $orange4-color;
                content: '';
                position: absolute;
                display: block;
                left: 0;
                bottom: -15px;
                height: 3px;
                width: 100%;
                background: $green-gradient;
            }
        }
        &:nth-child(4) {
            background: $purple-gradient;
            position: relative;
            &.active::before {
                // box-shadow: 0 0 0 2px #fff, 0 0 0 4px $orange4-color;
                content: '';
                position: absolute;
                display: block;
                left: 0;
                bottom: -15px;
                height: 3px;
                width: 100%;
                background: $purple-gradient;
            }
        }
        &:nth-child(5) {
            background: $red-gradient;
            position: relative;
            &.active::before {
                // box-shadow: 0 0 0 2px #fff, 0 0 0 4px $orange4-color;
                content: '';
                position: absolute;
                display: block;
                left: 0;
                bottom: -15px;
                height: 3px;
                width: 100%;
                background: $red-gradient;
            }
        }
    }
    &__item__number {
        @include font-semibold-20px;
    }
    &__item__text {
        margin-top: 5px;
        @include font-medium-12px;
    }
}
