@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/variables.scss';

.alert {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 3;
    text-align: center;
    // padding: 30px 10px;
    
    &--active {
        // display: block;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__dialog {
        position: relative;
        padding: 20px;
        // margin: 0 auto;
        width: 80%;
        // height: 500px;
        max-width: 410px;
        background-color: #fff;
        border-radius: 16px;
    }
    &__footer {
        display: flex;
        justify-content: center;
        gap: 20px;
    }
    // &__close {
    //     position: absolute;
    //     top: 10px;
    //     right: 20px;
    //     cursor: pointer;
    //     font-size: 30px;
    // }
    &__btn {
        margin-top: 20px;
        display: block;
        padding: 10px 20px;
        border-radius: 8px;
        cursor: pointer;
    }
    &__btn--ok {
        // margin: 20px auto 0 auto;
    }
    // &__btn--confirm {

    // }
    // &__btn--success {

    // }
    &__title {
        text-align: center;
        @include font-semibold-20px;
    }
}