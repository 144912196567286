@import '../../assets/styles/variables.scss';

.checkbox {
    & label {
        cursor: pointer;
    }

    & p {
        margin-left: 30px;
        position: relative;
    }
    & input[type='checkbox'] {
        display: none;
    }
    & input[type='checkbox']:checked {
        & + p::before {
            background-color: $orange4-color;
        }
        & + p::after {
            scale: 1;
        }
    } 
    & p::before {
        content: '';
        display: block;
        position: absolute;
        left: -30px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        height: 16px;
        width: 16px;
        border-radius: 4px;
        background-color: $grey5-color;
    }
    & p::after {
        content: '';
        scale: 0;
        position: absolute;
        top: 4px;
        left: -27px;
        height: 10px;
        width: 12px;
        background: url('../../assets/icons/components/checkbox-arrow.svg') no-repeat;
        background-size: cover;
        background-position: center;
        transition: all 0.2s;
    }
    & input:not(:checked) + p:hover::before {
        background-color: $orange4-color;
    }

}