@import '../../../assets//styles/variables.scss';

.quick-menu {
    position: fixed;
    bottom: 0;
    height: 74px;
    width: 100%;
    margin: 0 auto;
    // background-color: $white-color;
    display: grid;
    grid-template-columns: 1fr 90px 1fr;
    
    &__left-side, &__right-side {
        // padding-top: 18px;
        display: flex;
        justify-content: space-around;
        background-color: $white-color;
    }
    &__center-side {
        position: relative;
        background: url('../../../assets/images/bg_quick-menu_center.png') center center/cover no-repeat;
    }
    &__center-side__btn {
        position: absolute;
        top: -30px;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        background: $orange1-gradient;
        
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    &__link {
        position: relative;
        padding-top: 18px;
    }
    &__link .unread-badge {
        position: absolute;
        top: 18px;
        right: 0;
    }
    &__link__icon {
        height: 22px;
        width: 22px;
        color: $grey4-color;
    }
    &__link__icon--active {
        color: $orange3-color;
    }
    &--unvisible {
        display: none;
    }
}

@media (min-width: 992px) {
    .quick-menu {
        display: none;
    }
}