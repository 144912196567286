@import '../../assets/styles/variables.scss';

.star-rating {
    font-size: 18px;
    display: flex;
    column-gap: 10px;
}

.star-item {
    cursor: pointer;
}

.star-item:hover {
    scale: 1.5;
}