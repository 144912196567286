@import '../../assets/styles/variables.scss';

.file-uploader {
    &__input>label {
        display: block;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    &__input>input {
        display: none;
    }
    &__files-list {
        margin-top: 10px;
    }
    &__files-list__item {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
    }
    &__btn-delete {
        font-size: 20px;
        color: $red2-color;
        cursor: pointer;
    }
}