@import "variables";
@import "mixins";

*, *::before, *::after {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    // border: solid 1px red;
}
body {
    font-family: Inter;
    color: $black-color;
}


a {
    text-decoration: none;
}

ul, ol, li {
    list-style: none;
}

h1 {
    @media (max-width: 768px) {
        font-size: 1.5em;
    }
}

.page {
    min-height: 100vH;
    background-color: $grey1-color;
}

.container {
    padding: 0 10px;
    margin: 0 auto;
    @media (min-width: 375px) {
        padding: 0 16px;
    }
    // @media (min-width: 576px) {
    //     padding: 0 13px;
    // }
    @media (min-width: 768px) {
        padding: 0 20px;
    }
    @media (min-width: 992px) {
        padding: 0 24px;
    }
    @media (min-width: 1200px) {
        padding: 0 30px;
    }
    @media (min-width: 1400px) {
        padding: 0;
        max-width: 1320px;
    }
}

.container--small {

    @media (min-width: 1400px) {
        padding: 0 40px;
    }
}


//Стили кнопок
.btn {
    display: block;
    // padding: 5px 30px;
    text-align: center;
    // font-size: 14px;
    // line-height: 18px;
    // font-weight: 500;
    border: none;
    // border-radius: 20px;
    cursor: pointer;
    transition: background .2s;
}
//Форма кнопок и размер текста
.btn--primary {
    padding: 7px 30px;
    border-radius: 20px;
    @include font-medium-14px;
    // font-size: 14px;
    // line-height: 18px;
    // font-weight: 500;
    
}
.btn--secondary {
    padding: 4px 8px; 
    border-radius: 6px;
    @include font-medium-12px
    // font-size: 12px;
    
}

//Цвета кнопок
.btn--orange {
    background: $orange1-gradient;
    color: #FFFFFF;
    // box-shadow: rgb(170 170 170) 2px 2px 4px 0px;
    &:not(:disabled):hover {
                background: linear-gradient(135deg, #FCCF31 0%, #F55555 100%);
            }
}
.btn--orange-dark {
    background: $orange7-color;
    color: #000000;
    // box-shadow: rgb(170 170 170) 2px 2px 4px 0px;
    // &:not(:disabled):hover {
    //             background: linear-gradient(135deg, #FCCF31 0%, #F55555 100%);
    //         }
}

.btn--green {
    background-color: $green3-color;
    color: #fff;
    filter: opacity(90%);
    &:hover {
        filter: opacity(100%);
    }
    
}
.btn--green-dark {
    background-color: rgb(104, 150, 90);
    color: #fff;
    filter: opacity(90%);
    &:hover:not(.btn--disabled) {
        filter: opacity(100%);
    }
}

.btn--red {
    background-color: $red2-color;
    color: #fff;
}

.btn--transparent {
    background: none transparent;
    color: rgb(173, 173, 173);
}

.btn--white {
    background: #fff;
    color: $grey5-color;
    &:hover {
        background: $grey2-color;
    }
}

.btn--grey {
    background: $grey8-color;
    color: #fff;
    // &:hover {
    //     background: $grey2-color;
    // }
}

.btn--outline {
    background: #fff;
    color: $grey5-color;
    border: 2px solid $grey5-color;
    &:hover {
        background: $grey2-color;
        border-color: transparent;
    }
}

.btn-border--red {
    // background: #fff;
    color: $red2-color;
    border-color: $red2-color;
    &:hover {
        background: $red2-color;
        color: #fff;
        border: none;
    }
}

.btn-border--green {
    color: rgb(104, 150, 90);
    border-color: rgb(104, 150, 90);
    &:hover {
        background: rgb(104, 150, 90);
        color: #fff;
        border-color: transparent;
    }
}


.btn--disabled {
    filter: opacity(40%);
    cursor: auto;
    &:hover {
        background-color: auto;
    }
}

.buttons-group {
    display: flex;
    justify-content: space-between; 
    column-gap: 30px;
}


.form {
    margin: 20px auto 0;
    padding: 30px 20px;
    max-width: 410px;
    background-color: rgb(255, 255, 255);
    border-radius: 16px;
    box-shadow: 0px 4px 26px rgb(0 0 0 / 50%);
    &__title {
        @include font-semibold-20px;
        text-align: center;
    }
    &__subtitle {
        @include font-semibold-15px;
    }
    // &__btn {
    //     padding: 5px;
    //     margin: 30px auto 0 auto;
    //     height: 40px;
    //     width: 210px;
    //     letter-spacing: 2px;
    // }
    // &__btn--disabled {
    //     filter: opacity(40%);
    //     cursor: auto;
    // }
    &__line {
        margin-top: 30px;
        height: 1px;
        background-color: rgb(222, 222, 222);
    }
    &__notification {
        @include font-regular-10px;
        margin-top: 10px;
    }
    @media (min-width: 400px) {
        padding: 30px;
    }
}

.input {
    display: block;
    height: 40px;
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    border: 1px solid rgba(171, 171, 171, 0.41);
    background: #fff;
    border-radius: 16px;
    font-size: 14px;
    color: $black-color;
    &--grey {
        background: none rgb(252, 252, 252);
    }
    &__label {
        display: block;
        @include font-regular-12px;
    }
    &--error {
        border-color: red;
    }
    &--textarea {
        padding: 10px;
        height: 104px;
    }
    &--header {
        margin-top: 0;
        border-radius: 8px;
        height: 36px;
        &:focus {
            outline: none !important;
            border:2px solid $orange2-color;
            // box-shadow: 0 0 10px #719ECE;
        }
    }
    @media (max-width: 768px) {
        &:focus {
            font-size: 16px;
        }
    }
}

.input-group {
    margin-top: 20px;
    height: auto;
}

.checkbox-group {
    margin-top: 20px;
}
.checkbox {
    &__label {
        margin-left: 10px;
    }
}
.placeholder-black {
    &::placeholder {
        color: #09101D;
    }
}

.main {
    &__wrapper {
        
        display: flex;
        background-color: $grey1-color;
        height: calc(100vH - 52px);
        @media (min-width: 992px) {
            height: calc(100vH - 66px);
        }
        @media (min-width: 1400px) {
            height: calc(100vH - 71px);
        }
    }
    &__content {
        position: relative;
        padding: 24px 0 120px;
        height: 100%;
        width: 100%;
        overflow: auto;
    }
}




//Универсальные модификаторы

.d-flex {
    display: flex;
}
.d-block {
    display: block;
}
.d-none {
    display: none;
}
.justify-between {
    justify-content: space-between;
}
.justify-center {
    justify-content: center;
}
.items-center {
    align-items: center;
}

.gap-x-10 {
    column-gap: 10px;
}
.gap-x-30 {
    column-gap: 30px;
}

.absolute {
    position: absolute;
}
.top-auto {
    top: auto;
}

.m-center {
    margin: 0 auto;
}

.mt--14 {
    margin-top: -14px;
}
.mt-0 {
    margin-top: 0;
}
.mt-5 {
    margin-top: 5px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-12 {
    margin-top: 12px;
}
.mt-16 {
    margin-top: 16px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-24 {
    margin-top: 24px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-40 {
    margin-top: 40px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mr-5 {
    margin-right: 5px;
}
.mr-10 {
    margin-right: 10px;
}

.p-5 {
    padding: 5px;
}
.pt-10 {
    padding-top: 10px;
}

.h-16 {
    height: 16px;
}
.h-20 {
    height: 20px;
}
.h-24 {
    height: 24px;
}
.h-30 {
    height: 30px;
}
.h-40 {
    height: 40px;
}
.h-100 {
    height: 100px;
}
.h-full {
    height: 100%;
}
.h-auto {
    height: auto;
}
.mh-400 {
    max-height: 400px;
}

.w-16 {
    width: 16px;
}
.w-20 {
    width: 20px;
}
.w-24 {
    width: 24px;
}
.w-30 {
    width: 30px;
}
.w-40 {
    width: 40px;
}
.w-100 {
    width: 100px;
}
.w-120 {
    width: 120px;
}
.w-130 {
    width: 130px;
}
.w-140 {
    width: 140px;
}
.w-170 {
    width: 170px;
}
.w-210 {
    width: 210px;
}
.w-full {
    width: 100%;
}

.fs-10 {
    font-size: 10px;
}
.fs-11 {
    font-size: 11px;
}
.fs-12 {
    font-size: 12px;
}
.fs-14 {
    font-size: 14px;
}
.fs-20 {
    font-size: 20px;
}
.fs-24 {
    font-size: 24px;
}
.fs-36 {
    font-size: 36px;
}

.color-orange4 {
    color: $orange4-color;
}
.color-orange5 {
    color: $orange5-color;
}
.color-grey6 {
    color: $grey6-color;
}


.cursor-pointer {
    cursor: pointer;
}
.ls-2 {
    letter-spacing: 2px;
}

.color-orange-4 {
    color: $orange4-color;
}

.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600;
}

.text-center {
    text-align: center;
}
.text-end {
    text-align: end;
}

.lh-20 {
    line-height: 20px;
}