.chat-room {
    display: none;
    width: 100%;
    position: relative;
    flex-direction: column;

    &--visible {
        display: flex;
    }

    @media (min-width: 992px) {
        display: flex;
    }
}