@import '../../assets/styles/variables.scss';

.customer-settings {
    margin-top: 24px;
    &__foto {
        position: relative;
        height: 104px;
        // width: 104px;
        // margin: 0 auto;
        display: flex;
    }
    &__foto__img {
        height: 100%;
        // width: 100%;
        width: auto;
        border-radius: 50%;
        margin: 0 auto;
    }
    &__foto__plus {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background: $orange1-gradient;
        color: $white-color;
        cursor: pointer;
    }
}

#userPhotoUploader {
    display: none;
}