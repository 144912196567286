.home-page .container {
    padding: 0 20px;
    margin: 0 auto;
    @media (min-width: 992px) {
        padding: 0 24px;
    }
    @media (min-width: 1200px) {
        padding: 0 30px;
    }
    @media (min-width: 1400px) {
        padding: 0;
        max-width: 1320px;
    }
}

.home-page .btn {
    padding: 15px 30px;
    width: fit-content;
    display: block;
    border: none;
    background-color: #E39A0F;
    color: white;
    text-align: center;
    font-size: inherit;
    font-weight: 500;
    
    cursor: pointer;
    transition: background 0.2s;
}
.home-page .btn.btn--grey {
    background-color: #4A474C;
}



.home-page {
    font-family: Gilroy;
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
    @media (min-width: 576px) {
        font-size: 14px;
        line-height: 140%;
    }
    @media (min-width: 768px) {
        font-size: 16px;
        line-height: 140%;
    }
    @media (min-width: 992px) {
        font-size: 18px;
        line-height: 140%;
    }
    @media (min-width: 1400px) {
        font-size: 20px;
        line-height: 140%;
    }
    & .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 140%;
        @media (min-width: 576px) {
            font-size: 24px;
            line-height: 140%;
        }
        @media (min-width: 768px) {
            font-size: 30px;
            line-height: 140%;
        }
        @media (min-width: 992px) {
            font-size: 36px;
            line-height: 140%;
        }
        @media (min-width: 1400px) {
            font-size: 48px;
            line-height: 140%;
        }
    }
    & .subtitle {
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
        @media (min-width: 576px) {
            font-size: 18px;
            line-height: 140%;
        }
        @media (min-width: 768px) {
            font-size: 20px;
            line-height: 140%;
        }
        @media (min-width: 992px) {
            font-size: 24px;
            line-height: 140%;
        }
        @media (min-width: 1400px) {
            font-size: 32px;
            line-height: 140%;
        }
    }
}

.color--main {
    color: #E39A0F;
}

.color--second {
    color: #00787F;
}