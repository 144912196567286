@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/gilroy/Gilroy-Regular.woff') format('woff'),
         url('../fonts//gilroy/Gilroy-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/gilroy/Gilroy-Medium.woff') format('woff'),
         url('../fonts//gilroy/Gilroy-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/gilroy/Gilroy-Semibold.woff') format('woff'),
         url('../fonts//gilroy/Gilroy-Semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/gilroy/Gilroy-Bold.woff') format('woff'),
         url('../fonts//gilroy/Gilroy-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}