.faq {
    background-color: #fff;
    margin-top: 30px;
    // padding: 30px 0;
    &__question {
        // padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
        // border-bottom: 1px solid black;
        cursor: pointer;
    }
    &__question__text {
        font-weight: 600;
    }
    &__question__icon .plus {
        height: 2px;
        width: 16px;
        background-color: #787486;
        position: relative;
        
        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            height: 2px;
            width: 16px;
            background-color: #787486;
            transform: rotate(90deg);
            transition: all .4s;
        }
    }
    & .active &__question__icon .plus {
        &::after {
            transform: rotate(0deg);
        }
    }
    &__answer {
        margin-top: 20px;
    }
    &__answer span {
        margin-right: 10px;
        font-weight: 600;
    }
    & .accordion {
        padding: 20px;
        border-bottom: 1px solid #dfdfdf;
    }
}